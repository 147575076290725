:root {
    --fontFamily1: Verdana;
    --fontFamily2: Verdana;
    --fontFamilySarabun: "Sarabun", sans-serif;
    --fontWeightLight: 300;
    --fontWeightRegular: 400;
    --fontWeightBold: 700;
    --mainColor: #4f8201;
    --footerBG: #1a5d00;
    --defaultBtnColor: #dc9517;
    --defaultBtnColor-2: #5a7500;
    --defaultBtnColor-3: #5a7500;
    --optionalColor: #0eb582;
    --whiteColor: #ffffff;
    --blackColor: #252525;
    --paragraphColor: #727695;

    --mainColor-2: hwb(84 0% 49%);
    --mainColor-3: #4d4d4d;
    --mat-paginator: #dc9517;
    --cardDocumentColor: #9db553;
    --bodyBgColor: #f2f2f2;
    --bodyBgColor-2: #c2d9ab;
    --bodyBgColor-3: #e4e4e4;
    // --bodyContact:#4f8201;
    --fontSize: 15px;
    --transition: 0.5s;
}

// :root {
//     --fontFamily1: Verdana;
//     --fontFamily2: Verdana;

//     --mainColor: #ff1949;
//     --footerBG: #252525;
//     --defaultBtnColor: #ff1949;
//     --defaultBtnColor-2: #ff1949;
//     --defaultBtnColor-3: #fb1a47;
//     --optionalColor: #0eb582;
//     --whiteColor: #ffffff;
//     --blackColor: #252525;
//     --paragraphColor: #727695;

//     --mainColor-2: #ffffff;
//     --mainColor-3: #ff1949;
//     --mat-paginator: #ffffff;
//     --cardDocumentColor: #ffffff;
//     --bodyBgColor: #ffffff;
//     --bodyBgColor-2: #ffffff;
//     --bodyBgColor-3: #ffffff;
//     --fontSize: 15px;
//     --transition: 0.5s;
// }
