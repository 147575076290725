/* Default theme. */
@import "~@vime/core/themes/default.css";
@import "/src/app/core/constants/constant-styles.scss";
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@100;300;700&display=swap");
.myapp-no-padding-dialog .mat-dialog-container {
    padding: 0px !important;
    // background-color: transparent;
    background-color: white;
}

@font-face {
    font-family: "sarabunbold";
    src: url("./assets/fonts/sarabun/sarabun-bold-webfont.woff2")
            format("woff2"),
        url("./assets/fonts/sarabun/sarabun-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sarabunextralight";
    src: url("./assets/fonts/sarabun/sarabun-extralight-webfont.woff2")
            format("woff2"),
        url("./assets/fonts/sarabun/sarabun-extralight-webfont.woff")
            format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sarabunlight";
    src: url("./assets/fonts/sarabun/sarabun-light-webfont.woff2")
            format("woff2"),
        url("./assets/fonts/sarabun/sarabun-light-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "sarabunthin";
    src: url("./assets/fonts/sarabun/sarabun-thin-webfont.woff2")
            format("woff2"),
        url("./assets/fonts/sarabun/sarabun-thin-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

body {
    background-color: var(--whiteColor);
    padding: 0;
    margin: 0;
    font: {
        //     // font-family: "sarabunlight";
        //     font-family: "sarabunlight";
        //     size: var(--fontSize);
        // }
        // .sarabun-light {
        font-family: var(--fontFamilySarabun);
        font-size: var(--fontSize);
        font-weight: var(--fontWeightRegular);
    }
}

p,
label,
span,
a {
    // font-family: "sarabunlight";
    font-family: var(--fontFamilySarabun);
    //   font-family: cursive;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--blackColor);
    font-family: var(--fontFamilySarabun);
    &:last-child {
        margin-bottom: 0;
    }
}
a {
    text-decoration: none;
    transition: var(--transition);
    color: var(--blackColor);

    &:hover,
    &:focus {
        color: var(--mainColor);
        text-decoration: none;
    }
}
input,
button,
a {
    outline: 0 !important;
}
p {
    margin-bottom: 15px;
    line-height: 1.7;
    color: var(--paragraphColor);
    // font-family: "sarabunlight";
    font-family: var(--fontFamilySarabun);
    size: var(--fontSize);
    &:last-child {
        margin-bottom: 0;
    }
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
img {
    max-width: 100%;
    height: auto;
}
.ptb-100 {
    padding: {
        top: 60px;
        bottom: 100px;
    }
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.ptb-70 {
    padding: {
        top: 70px;
        bottom: 70px;
    }
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    padding-bottom: 70px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mt-30 {
    margin-top: 30px;
}
.bg-F7F9FB {
    background-color: #f7f9fb;
}

/*default-btn*/
.default-btn {
    transition: var(--transition);
    display: inline-block;
    padding: 13px 25px 12px 55px;
    position: relative;
    background-color: var(--defaultBtnColor);
    color: var(--whiteColor);
    border: {
        width: 2px;
        style: solid;
        // color: #03045e;
        color: var(--defaultBtnColor);
        radius: 1px;
    }
    font: {
        size: 14.5px;
        weight: 700;
    }
    .label,
    .icon-arrow {
        backface-visibility: hidden;
        transform: translateZ(0);
        perspective: 1000;
    }
    .label {
        display: inline-block;
        transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1);
    }
    .icon-arrow {
        color: var(--whiteColor);
        top: 11px;
        transition: transform 0.5s cubic-bezier(0.86, 0, 0.07, 1),
            opacity 0.4s cubic-bezier(0.86, 0, 0.07, 1);
        font-size: 25px;
    }
    .icon-arrow {
        &.before {
            left: 20px;
            position: absolute;
            transform-origin: left center;
        }
        &.after {
            opacity: 0;
            position: absolute;
            right: 20px;
            transform: translateX(75%) scaleX(0.1);
            transform-origin: right center;
        }
    }
    &:hover,
    &:focus {
        background-color: var(--optionalColor);
        color: var(--whiteColor);
        border-color: var(--optionalColor);

        .label {
            transform: translateX(-32px);
        }
        .icon-arrow {
            &.before {
                opacity: 0;
                transform: translateX(-75%) scaleX(0.1);
            }
            &.after {
                opacity: 1;
                transform: translateX(0) scaleX(1);
            }
        }
    }
}

/*section-title*/
.section-title {
    text-align: center;
    position: relative;
    margin: {
        top: -5px;
        bottom: 60px;
    }
    .sub-title {
        text-transform: uppercase;
        display: block;
        color: var(--mainColor);
        font-weight: 500;
        margin-bottom: 10px;
    }
    h2 {
        margin-bottom: 12px;
        font: {
            size: 37px;
            weight: 700;
        }
    }
    p {
        max-width: 620px;
        line-height: 1.8;
        margin: {
            left: auto;
            right: auto;
        }
    }
    .default-btn {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }
    &.text-start {
        h2 {
            max-width: 620px;
            margin-bottom: 0;
        }
        p {
            margin: {
                bottom: 0;
                left: 0;
                top: 15px;
                right: 0;
            }
        }
    }
}

/*form-control*/
.form-control {
    height: 48px;
    padding: 0 0 0 12px;
    line-height: initial;
    color: var(--blackColor);
    background-color: var(--whiteColor);
    border: 1px solid #e6e9fc;
    border-radius: 3px;
    transition: var(--transition);
    font: {
        size: var(--fontSize);
        weight: 400;
    }
    &::placeholder {
        color: #c4c5d0;
    }
    &:focus {
        box-shadow: unset;
        border-color: #d7d9eb;
    }
}
textarea.form-control {
    height: auto !important;
    padding-top: 12px;
}

/*owl-carousel-css*/
.mission-content {
    .owl-theme {
        .owl-nav {
            margin-top: 0;

            [class*="owl-"] {
                color: var(--blackColor);
                font-size: 25px;
                margin: 0;
                padding: 0;
                background: var(--whiteColor);
                width: 50px;
                height: 50px;
                line-height: 56px;
                position: absolute;
                left: -84px;
                transition: var(--transition);
                border: none;
                top: 50%;
                transform: translateY(-50%);
                box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
                border-radius: 50%;

                &.owl-next {
                    left: auto;
                    right: -84px;
                }
                &:hover,
                &:focus {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
    }
}
.courses-categories-slides {
    .owl-theme {
        margin-top: -30px;

        .single-categories-courses-box {
            margin-top: 30px;
        }
        .single-categories-courses-item {
            margin-top: 30px;
        }
        .owl-nav {
            margin-top: 0;

            [class*="owl-"] {
                color: #87a1c1;
                font-size: 25px;
                margin: 0;
                padding: 0;
                background: transparent;
                width: 50px;
                height: 50px;
                line-height: 51px;
                position: absolute;
                left: -50px;
                transition: var(--transition);
                top: 50%;
                transform: translateY(-50%);
                opacity: 0;
                visibility: hidden;
                border: {
                    radius: 50%;
                    style: solid;
                    color: #e3e4ef;
                    width: 2px;
                }
                &.owl-next {
                    left: auto;
                    right: -50px;
                }
                &:hover,
                &:focus {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                    border-color: var(--mainColor);
                    box-shadow: 0px 8px 16px 0px rgba(241, 96, 1, 0.2);
                }
                &.disabled {
                    color: #e3e4ef;

                    &:hover,
                    &:focus {
                        background-color: transparent;
                        color: #e3e4ef;
                        border-color: #e3e4ef;
                        box-shadow: unset;
                    }
                }
            }
        }
        &:hover,
        &:focus {
            .owl-nav {
                [class*="owl-"] {
                    left: -70px;
                    opacity: 1;
                    visibility: visible;

                    &.owl-next {
                        left: auto;
                        right: -70px;
                    }
                }
            }
        }
    }
}
.courses-slides {
    .owl-theme {
        margin-top: -25px;

        .single-courses-box {
            margin-top: 25px;
        }
        .owl-nav {
            margin-top: 0;

            [class*="owl-"] {
                color: #87a1c1;
                font-size: 25px;
                margin: 0;
                padding: 0;
                background: transparent;
                width: 50px;
                height: 50px;
                line-height: 51px;
                position: absolute;
                left: -50px;
                transition: var(--transition);
                top: 50%;
                transform: translateY(-50%);
                opacity: 0;
                visibility: hidden;
                border: {
                    radius: 50%;
                    style: solid;
                    color: #e3e4ef;
                    width: 2px;
                }
                &.owl-next {
                    left: auto;
                    right: -50px;
                }
                &:hover,
                &:focus {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                    border-color: var(--mainColor);
                    box-shadow: 0px 8px 16px 0px rgba(241, 96, 1, 0.2);
                }
                &.disabled {
                    color: #e3e4ef;

                    &:hover,
                    &:focus {
                        background-color: transparent;
                        color: #e3e4ef;
                        border-color: #e3e4ef;
                        box-shadow: unset;
                    }
                }
            }
        }
        &:hover,
        &:focus {
            .owl-nav {
                [class*="owl-"] {
                    left: -70px;
                    opacity: 1;
                    visibility: visible;

                    &.owl-next {
                        left: auto;
                        right: -70px;
                    }
                }
            }
        }
        .owl-dots {
            margin-top: 15px;

            .owl-dot {
                span {
                    width: 13px;
                    height: 13px;
                    margin: 0 5px;
                    background: #dddddd;
                    transition: var(--transition);
                    border-radius: 50%;
                    transform: scale(0.9);
                }
                &:hover,
                &.active {
                    span {
                        background-color: var(--mainColor);
                    }
                }
                &.active {
                    span {
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
}
.instructor-slides {
    .owl-theme {
        .owl-nav {
            margin-top: 0;

            [class*="owl-"] {
                color: #87a1c1;
                font-size: 25px;
                margin: 0;
                padding: 0;
                background: transparent;
                width: 50px;
                height: 50px;
                line-height: 51px;
                position: absolute;
                left: -50px;
                transition: var(--transition);
                top: 50%;
                transform: translateY(-50%);
                opacity: 0;
                visibility: hidden;
                border: {
                    radius: 50%;
                    style: solid;
                    color: #e3e4ef;
                    width: 2px;
                }
                &.owl-next {
                    left: auto;
                    right: -50px;
                }
                &:hover,
                &:focus {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                    border-color: var(--mainColor);
                    box-shadow: 0px 8px 16px 0px rgba(241, 96, 1, 0.2);
                }
                &.disabled {
                    color: #e3e4ef;

                    &:hover,
                    &:focus {
                        background-color: transparent;
                        color: #e3e4ef;
                        border-color: #e3e4ef;
                        box-shadow: unset;
                    }
                }
            }
        }
        &:hover,
        &:focus {
            .owl-nav {
                [class*="owl-"] {
                    left: -70px;
                    opacity: 1;
                    visibility: visible;

                    &.owl-next {
                        left: auto;
                        right: -70px;
                    }
                }
            }
        }
    }
}
.partner-slides {
    .owl-theme {
        .owl-stage {
            display: flex;
            align-items: center;
        }
    }
}
.feedback-slides {
    .owl-theme {
        margin-bottom: -40px;

        .single-feedback-item {
            margin-bottom: 40px;
        }
        .owl-nav {
            margin-top: 0;

            [class*="owl-"] {
                color: #87a1c1;
                font-size: 25px;
                margin: 0;
                padding: 0;
                background: transparent;
                width: 50px;
                height: 50px;
                line-height: 51px;
                position: absolute;
                left: 50px;
                transition: var(--transition);
                top: 50%;
                transform: translateY(-50%);
                border: {
                    radius: 50%;
                    style: solid;
                    color: #e3e4ef;
                    width: 2px;
                }
                &.owl-next {
                    left: auto;
                    right: 50px;
                }
                &:hover,
                &:focus {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                    border-color: var(--mainColor);
                    box-shadow: 0px 8px 16px 0px rgba(241, 96, 1, 0.2);
                }
                &.disabled {
                    color: #e3e4ef;

                    &:hover,
                    &:focus {
                        background-color: transparent;
                        color: #e3e4ef;
                        border-color: #e3e4ef;
                        box-shadow: unset;
                    }
                }
            }
        }
        &:hover,
        &:focus {
            .owl-nav {
                [class*="owl-"] {
                    left: 70px;

                    &.owl-next {
                        left: auto;
                        right: 70px;
                    }
                }
            }
        }
    }
}
.blog-slides {
    .owl-theme {
        .owl-nav {
            margin-top: 0;

            [class*="owl-"] {
                color: #87a1c1;
                font-size: 25px;
                margin: 0;
                padding: 0;
                background: transparent;
                width: 50px;
                height: 50px;
                line-height: 51px;
                position: absolute;
                left: -50px;
                transition: var(--transition);
                top: 50%;
                transform: translateY(-50%);
                opacity: 0;
                visibility: hidden;
                border: {
                    radius: 50%;
                    style: solid;
                    color: #e3e4ef;
                    width: 2px;
                }
                &.owl-next {
                    left: auto;
                    right: -50px;
                }
                &:hover,
                &:focus {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                    border-color: var(--mainColor);
                    box-shadow: 0px 8px 16px 0px rgba(241, 96, 1, 0.2);
                }
                &.disabled {
                    color: #e3e4ef;

                    &:hover,
                    &:focus {
                        background-color: transparent;
                        color: #e3e4ef;
                        border-color: #e3e4ef;
                        box-shadow: unset;
                    }
                }
            }
        }
        &:hover,
        &:focus {
            .owl-nav {
                [class*="owl-"] {
                    left: -70px;
                    opacity: 1;
                    visibility: visible;

                    &.owl-next {
                        left: auto;
                        right: -70px;
                    }
                }
            }
        }
    }
}
.home-slides {
    .owl-theme {
        .owl-nav {
            margin-top: 0;
            opacity: 0;
            visibility: visible;
            transition: var(--transition);

            [class*="owl-"] {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                color: var(--blackColor);
                font-size: 35px;
                margin: 0;
                padding: 0;
                background: rgba(255, 255, 255, 0.7);
                border-radius: 0;
                width: 55px;
                height: 55px;
                transition: var(--transition);

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &.owl-next {
                    left: auto;
                    right: 20px;
                }
                &:hover {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
        .owl-dots {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 15px;

            .owl-dot {
                span {
                    width: 13px;
                    height: 13px;
                    margin: 0 5px;
                    background: var(--whiteColor);
                    transition: var(--transition);
                    border-radius: 50%;
                    transform: scale(0.9);
                }
                &:hover,
                &.active {
                    span {
                        background-color: var(--mainColor);
                    }
                }
                &.active {
                    span {
                        transform: scale(1.2);
                    }
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
.testimonials-slides {
    .owl-theme {
        .owl-dots {
            margin-top: 15px;

            .owl-dot {
                span {
                    width: 13px;
                    height: 13px;
                    margin: 0 5px;
                    background: #dddddd;
                    transition: var(--transition);
                    border-radius: 50%;
                    transform: scale(0.9);
                }
                &:hover,
                &.active {
                    span {
                        background-color: var(--mainColor);
                    }
                }
                &.active {
                    span {
                        transform: scale(1.2);
                    }
                }
            }
        }
    }
}
.products-details-image {
    .slick-slider {
        position: relative;
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .slick-dots {
        padding: 0;
        text-align: center;
        margin: 10px 0 0;
        list-style-type: none;
        display: flex;
        width: 100%;

        li {
            display: block;
            -ms-flex: 1;
            flex: 1;
            margin: 0 5px;

            button {
                padding: 0;
                outline: 0;
                border: none;
                margin: 0;
                cursor: pointer;
            }
            &:last-child {
                margin-right: 0;
            }
            &:first-child {
                margin-left: 0;
            }
        }
    }
    .slick-thumbs {
        display: none;
    }
}

/*keyframes-css*/
@keyframes loader {
    0% {
        left: -100px;
    }
    100% {
        left: 110%;
    }
}
@keyframes animate {
    17% {
        border-bottom-right-radius: 3px;
    }
    25% {
        transform: translateY(9px) rotate(22.5deg);
    }
    50% {
        transform: translateY(18px) scale(1, 0.9) rotate(45deg);
        border-bottom-right-radius: 40px;
    }
    75% {
        transform: translateY(9px) rotate(67.5deg);
    }
    100% {
        transform: translateY(0) rotate(90deg);
    }
}
@keyframes shadow {
    50% {
        transform: scale(1.2, 1);
    }
}
@keyframes rotateMe {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes border-transform {
    0%,
    100% {
        border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
    }
    14% {
        border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
    }
    28% {
        border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
    }
    42% {
        border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
    }
    56% {
        border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
    }
    70% {
        border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
    }
    84% {
        border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
    }
}
@keyframes ripple {
    0% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.75);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

/*extra-css*/
.courses-categories-area {
    position: relative;
    z-index: 1;

    &.bg-image {
        background-image: url(assets/img/pattern.jpg);
    }
}
.owl-item {
    &:nth-child(2),
    &:nth-child(8) {
        .single-categories-courses-box {
            background-color: #fea116;

            &:hover,
            &:focus {
                .icon {
                    border-color: #fea116 !important;
                    color: #fea116 !important;
                }
            }
        }
    }
    &:nth-child(3),
    &:nth-child(9) {
        .single-categories-courses-box {
            background-color: #1cb5a3;

            &:hover,
            &:focus {
                .icon {
                    border-color: #1cb5a3 !important;
                    color: #1cb5a3 !important;
                }
            }
        }
    }
    &:nth-child(4),
    &:nth-child(10) {
        .single-categories-courses-box {
            background-color: #3d64ff;

            &:hover,
            &:focus {
                .icon {
                    border-color: #3d64ff !important;
                    color: #3d64ff !important;
                }
            }
        }
    }
    &:nth-child(5),
    &:nth-child(11) {
        .single-categories-courses-box {
            background-color: #e74d57;

            &:hover,
            &:focus {
                .icon {
                    border-color: #e74d57 !important;
                    color: #e74d57 !important;
                }
            }
        }
    }
    &:nth-child(6),
    &:nth-child(12) {
        .single-categories-courses-box {
            background-color: #9764df;

            &:hover,
            &:focus {
                .icon {
                    border-color: #9764df !important;
                    color: #9764df !important;
                }
            }
        }
    }
}
.funfacts-area {
    overflow: hidden;
}
.funfacts-inner {
    overflow: hidden;
    background-color: var(--mainColor);
    border-radius: 15px;
    padding: 50px;
    position: relative;
    z-index: 1;
    text-align: center;

    .col-lg-3 {
        border-right: 1px solid rgba(255, 255, 255, 0.3);

        &:last-child {
            border-right: 0;
        }
    }
}
.partner-area {
    &.bg-color {
        background-color: var(--mainColor);

        .section-title {
            h2 {
                color: var(--whiteColor);
            }
        }
    }
}
.instructor-area {
    position: relative;
    z-index: 2;
}
.owl-item {
    &:nth-child(2),
    &:nth-child(6) {
        .single-instructor-box {
            .image {
                border-color: #ffe5be !important;

                .social {
                    li {
                        a {
                            &:hover {
                                color: var(--whiteColor) !important;
                                background-color: #fea116 !important;
                            }
                        }
                    }
                }
            }
            .content {
                span {
                    color: #fea116 !important;
                }
            }
            &:hover {
                .image {
                    border-color: #fea116 !important;
                }
            }
        }
    }
    &:nth-child(3),
    &:nth-child(5) {
        .single-instructor-box {
            .image {
                border-color: #b2f3df !important;

                .social {
                    li {
                        a {
                            &:hover {
                                color: var(--whiteColor) !important;
                                background-color: var(
                                    --optionalColor
                                ) !important;
                            }
                        }
                    }
                }
            }
            .content {
                span {
                    color: var(--optionalColor) !important;
                }
            }
            &:hover {
                .image {
                    border-color: var(--optionalColor) !important;
                }
            }
        }
    }
}
.owl-item {
    &:nth-child(2),
    &:nth-child(7) {
        .single-blog-post {
            .post-image {
                .tag {
                    a {
                        color: var(--optionalColor) !important;
                    }
                }
            }
            &:hover,
            &:focus {
                .post-image {
                    .tag {
                        a {
                            background-color: var(--optionalColor) !important;
                            color: var(--whiteColor) !important;
                        }
                    }
                }
            }
        }
    }
    &:nth-child(3),
    &:nth-child(8) {
        .single-blog-post {
            .post-image {
                .tag {
                    a {
                        color: #fea116 !important;
                    }
                }
            }
            &:hover,
            &:focus {
                .post-image {
                    .tag {
                        a {
                            background-color: #fea116 !important;
                            color: var(--whiteColor) !important;
                        }
                    }
                }
            }
        }
    }
    &:nth-child(4),
    &:nth-child(9) {
        .single-blog-post {
            .post-image {
                .tag {
                    a {
                        color: #1cb5a3 !important;
                    }
                }
            }
            &:hover,
            &:focus {
                .post-image {
                    .tag {
                        a {
                            background-color: #1cb5a3 !important;
                            color: var(--whiteColor) !important;
                        }
                    }
                }
            }
        }
    }
    &:nth-child(5),
    &:nth-child(10) {
        .single-blog-post {
            .post-image {
                .tag {
                    a {
                        color: #3d64ff !important;
                    }
                }
            }
            &:hover,
            &:focus {
                .post-image {
                    .tag {
                        a {
                            background-color: #3d64ff !important;
                            color: var(--whiteColor) !important;
                        }
                    }
                }
            }
        }
    }
}
.feedback-area {
    position: relative;
    z-index: 1;
    background-color: #f7f5f4;
}
.feedback-form-area {
    position: relative;
    z-index: 1;
    margin-top: -80px;
}
.testimonials-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.owl-item {
    .single-testimonials-item {
        box-shadow: unset;
        margin-top: 30px;
        transition: var(--transition);
        opacity: 0.7;
    }
    &.active {
        &.center {
            .single-testimonials-item {
                box-shadow: 6px 5px 25px rgba(0, 0, 0, 0.06);
                margin-top: 0;
                background-color: var(--mainColor);
                transition: var(--transition);
                opacity: 1;

                p {
                    color: var(--whiteColor);
                }
            }
        }
    }
}
.funfacts-style-two {
    overflow: hidden;
    background-color: var(--mainColor);
    position: relative;
    z-index: 1;
}
.slideshow-box {
    background-color: var(--blackColor);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    owl-carousel-o {
        width: 100%;
        height: 100%;
    }
    .slideshow-slides {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;

        li {
            span {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background: {
                    size: cover;
                    position: center center;
                    repeat: no-repeat;
                }
                &.bg1 {
                    background-image: url(assets/img/slideshow/slideshow1.jpg);
                }
                &.bg2 {
                    background-image: url(assets/img/slideshow/slideshow2.jpg);
                }
                &.bg3 {
                    background-image: url(assets/img/slideshow/slideshow3.jpg);
                }
                &.bg4 {
                    background-image: url(assets/img/slideshow/slideshow4.jpg);
                }
                &.bg5 {
                    background-image: url(assets/img/slideshow/slideshow5.jpg);
                }
            }
        }
        .owl-theme {
            height: 100%;
            width: 100%;
        }
        owl-stage {
            height: 100%;
            width: 100%;
        }
        .ng-tns-c18-0 {
            height: 100%;
            width: 100%;
        }
        .owl-stage-outer {
            height: 100%;
            width: 100%;

            .owl-stage {
                height: 100%;
                width: 100%;

                .owl-item {
                    height: 100% !important;
                }
            }
        }
    }
}
.woocommerce-topbar {
    .topbar-ordering-and-search {
        .topbar-ordering {
            .form-select {
                border: none;
                color: var(--paragraphColor);
                font-size: var(--fontSize);
                padding: 0;
                cursor: pointer;
                box-shadow: unset;
            }
        }
    }
}
.courses-topbar {
    margin-bottom: 40px;

    .topbar-result-count {
        p {
            line-height: initial;
            font: {
                // font-family: "sarabunlight";
                font-family: var(--fontFamilySarabun);
                size: 16px;
            }
        }
    }
    .topbar-ordering-and-search {
        text-align: end;

        .topbar-ordering {
            .form-select {
                border: none;
                box-shadow: unset;
                cursor: pointer;
                padding: 0;
                font-size: var(--fontSize);
                color: var(--paragraphColor);
            }
        }
        .topbar-search {
            form {
                position: relative;

                label {
                    display: block;
                    margin-bottom: 0;
                    position: absolute;
                    left: 20px;
                    top: 14.2px;
                    font-size: 18px;
                    color: var(--mainColor);
                }
                .input-search {
                    height: 50px;
                    color: var(--blackColor);
                    background-color: #eef5f9;
                    display: block;
                    width: 100%;
                    border-radius: 30px;
                    padding: 3px 15px 0 48px;
                    border: none;
                    transition: var(--transition);
                    font: {
                        size: 1rem;
                        weight: 400;
                    }
                    &::placeholder {
                        color: var(--paragraphColor);
                        transition: var(--transition);
                    }
                    &:focus {
                        &::placeholder {
                            color: transparent;
                        }
                    }
                }
            }
        }
    }
}
.divider {
    width: 100%;
    position: absolute;
    height: 6vw;
    bottom: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
    -webkit-mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
    mask-image: url("data:image/svg+xml;utf8,%3csvg viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cpath d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z' fill='%23fff'/%3e%3c/svg%3e");
    -webkit-mask-size: 100% 101%;
    mask-size: 100% 101%;
    background: var(--whiteColor);
}
.mr-2 {
    margin-right: 0.5rem;
}
.nav-tabset {
    padding-left: 0;
    list-style-type: none;
    margin: {
        top: -30px;
        bottom: 40px;
    }
    .nav-tab {
        display: inline-block;
        margin-right: 20px;

        span {
            transition: var(--transition);
            background-color: transparent;
            color: #727695;
            font-weight: 500;
            cursor: pointer;
            display: block;
            border: none;
            padding: 0;

            &:hover {
                color: var(--mainColor);
            }
        }
        &.active {
            span {
                color: var(--mainColor);
            }
        }
    }
}
.courses-list-tab {
    .nav-tabset {
        text-align: center;
        margin-bottom: 40px;
        list-style-type: none;
        background-color: var(--whiteColor);
        box-shadow: 0 2px 16px 0px rgba(146, 184, 255, 0.15);
        border-radius: 5px;
        padding: {
            left: 20px;
            right: 20px;
        }
        .nav-tab {
            display: inline-block;
            margin: {
                left: 15px;
                right: 15px;
            }
            &:last-child {
                margin-right: 0;
            }
            &:first-child {
                margin-left: 0;
            }
            span {
                display: inline-block;
                position: relative;
                font: {
                    size: 16px;
                    weight: 600;
                }
                padding: {
                    top: 15px;
                    bottom: 15px;
                }
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 3px;
                    transition: var(--transition);
                    background: #eeeeee;
                }
                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 3px;
                    transition: var(--transition);
                    background-color: var(--mainColor);
                    transform: scaleX(0);
                }
                &:hover {
                    color: var(--mainColor);

                    &::after {
                        transform: scaleX(1);
                    }
                }
            }
            &.active {
                span {
                    color: var(--mainColor);

                    &::after {
                        transform: scaleX(1);
                    }
                }
            }
        }
    }
}
.faq-accordion-tab {
    .nav-tabset {
        margin-bottom: 60px;
        text-align: center;

        li {
            margin: {
                left: 10px;
                right: 10px;
            }
            &:last-child {
                margin-right: 0;
            }
            &:first-child {
                margin-left: 0;
            }
            span {
                padding: 20px;
                border-radius: 5px;
                transition: var(--transition);
                box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13);
                font: {
                    size: 16px;
                    weight: 600;
                }
                &:hover {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
            &.active {
                span {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
    }
}
.products-details-tab {
    margin-top: 50px;

    .nav-tabset {
        margin-bottom: -1px;

        .nav-tab {
            display: inline-block;
            line-height: initial;
            margin-right: 10px;

            span {
                position: relative;
                display: inline-block;
                color: var(--blackColor);
                border: 1px dashed #eeeeee;
                padding: {
                    top: 13px;
                    left: 30px;
                    right: 30px;
                    bottom: 13px;
                }
                font: {
                    weight: 700;
                    size: var(--fontSize);
                }
                &:hover {
                    background-color: var(--mainColor);
                    border-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
            &.active {
                span {
                    background-color: var(--mainColor);
                    border-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .tabs-container {
        border: 1px dashed #eeeeee;
        padding: 30px;

        .products-details-tab-content {
            p {
                margin-bottom: 20px;
                line-height: 1.8;

                &:last-child {
                    margin-bottom: 0;
                }
            }
            .additional-information {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;
                text-align: start;

                li {
                    border: 1px solid #eeeeee;
                    border-bottom: none;
                    padding: 10px 15px 7px;
                    color: var(--paragraphColor);

                    &:last-child {
                        border-bottom: 1px solid #eeeeee;
                    }
                    span {
                        display: inline-block;
                        width: 30%;
                        color: var(--blackColor);
                        font: {
                            weight: 500;
                        }
                    }
                }
            }
            h3 {
                margin-bottom: 15px;
                font: {
                    size: 20px;
                    weight: 600;
                }
            }
            .product-review-form {
                .review-title {
                    position: relative;

                    .rating {
                        display: inline-block;

                        .bx.bxs-star {
                            color: #ffba0a;
                        }
                        i {
                            color: #ebebeb;
                            font-size: 18px;
                            margin-right: 2px;
                        }
                    }
                    p {
                        margin-bottom: 0;
                        display: inline-block;
                        padding-left: 5px;
                        line-height: initial;
                        position: relative;
                        top: -2px;
                    }
                    .default-btn {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }
                }
                .review-comments {
                    margin-top: 35px;

                    .review-item {
                        margin-top: 30px;
                        position: relative;
                        padding-right: 200px;
                        border-top: 1px dashed #eeeeee;
                        padding-top: 30px;

                        .rating {
                            .bx.bxs-star {
                                color: #ffba0a;
                            }
                            i {
                                font-size: 18px;
                                color: #ebebeb;
                                margin-right: -1px;
                            }
                        }
                        h3 {
                            font-size: 18px;
                            margin: {
                                top: 8px;
                                bottom: 10px;
                            }
                        }
                        span {
                            margin-bottom: 10px;
                            font-size: 12px;
                            display: block;

                            strong {
                                font-weight: 500;
                            }
                        }
                        p {
                            margin-bottom: 0;
                        }
                        .review-report-link {
                            position: absolute;
                            right: 0;
                            color: var(--paragraphColor);
                            top: 40px;
                            text-decoration: underline;
                            font: {
                                weight: 500;
                                size: 15px;
                            }
                            &:hover {
                                color: var(--mainColor);
                            }
                        }
                    }
                }
                .review-form {
                    margin-top: 30px;

                    h3 {
                        margin-bottom: 20px;
                    }
                    form {
                        .form-group {
                            margin-bottom: 15px;

                            .form-control {
                                display: block;
                                width: 100%;
                                background-color: #f4f4f4;
                                border: none;
                                padding: 0 0 0 15px;
                                height: 50px;
                                outline: 0;
                                border-radius: 3px;
                                font: {
                                    // font-family: "sarabunlight";
                                    font-family: var(--fontFamilySarabun);
                                    weight: 400;
                                    size: 14px;
                                }
                                &::placeholder {
                                    transition: var(--transition);
                                    color: var(--paragraphColor);
                                }
                                &:focus {
                                    &::placeholder {
                                        color: transparent;
                                    }
                                }
                            }
                            textarea.form-control {
                                padding-top: 15px;
                                height: auto;
                            }
                        }
                        .default-btn {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
}

/*Page Title CSS*/
.page-title-area {
    position: relative;
    z-index: 1;
    background: {
        color: var(--mainColor);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }
    padding: {
        top: 330px;
        bottom: 120px;
    }
    &.page-title-style-two {
        padding: {
            top: 330px;
            bottom: 120px;
        }
    }
    &.page-title-style-three {
        padding: {
            top: 180px;
            bottom: 140px;
        }
    }
    &.item-bg1 {
        background: {
            image: url(assets/img/page-title/classroom.jpg);
        }
    }
    &.item-bg2 {
        background: {
            image: url(./assets/img-home/image-e-learning-quand-les-outils-numeriques-deviennent-indispensables-750x375.jpg);
        }
    }
    &.item-bg3 {
        background: {
            image: url(assets/img/news_img/news_oil_img.jpg);
        }
    }
    &.item-bg4 {
        background: {
            image: url(assets/img/page-title/page-title4.jpg);
        }
    }
}
.page-title-content {
    ul {
        padding-left: 0;
        margin-bottom: 12px;
        list-style-type: none;

        li {
            display: inline-block;
            margin-right: 30px;
            position: relative;
            color: var(--whiteColor);

            a {
                display: inline-block;
                color: var(--whiteColor);
                opacity: 0.85;

                &:hover,
                &:focus {
                    color: var(--mainColor);
                    opacity: 1;
                }
            }
            &::before {
                content: "\e9fa";
                color: var(--whiteColor);
                position: absolute;
                right: -25px;
                // top: -4px;
                opacity: 0.85;
                font: {
                    family: "boxicons" !important;
                    weight: normal;
                    style: normal;
                    variant: normal;
                    size: 20px;
                }
            }
            &:last-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
        }
    }
    h2 {
        color: var(--whiteColor);
        margin-bottom: 0;
        font: {
            size: 42px;
            weight: 700;
        }
    }
}

/*Widget Sidebar CSS*/
.widget-area {
    .widget {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
        .widget-title {
            margin-bottom: 25px;
            position: relative;
            padding-left: 10px;
            border-left: 3px dashed #dfdfdf;
            font: {
                size: 19px;
                weight: 600;
            }
            &::before {
                content: "";
                position: absolute;
                left: -3px;
                top: 0;
                width: 3px;
                height: 6px;
                background: var(--mainColor);
            }
            &::after {
                content: "";
                position: absolute;
                left: -3px;
                top: 16px;
                width: 3px;
                height: 6px;
                background: var(--optionalColor);
            }
        }
    }
    .widget_search {
        form {
            position: relative;

            .screen-reader-text {
                display: none;
            }
            label {
                display: block;
                margin-bottom: 0;
            }
            .search-field {
                height: 55px;
                color: var(--blackColor);
                background-color: #eef5f9;
                display: block;
                width: 100%;
                border-radius: 5px;
                padding: 3px 0 0 18px;
                border: none;
                transition: var(--transition);
                font: {
                    size: 14px;
                    weight: 400;
                }
                &::placeholder {
                    color: var(--paragraphColor);
                    transition: var(--transition);
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            button {
                border: none;
                background-color: var(--mainColor);
                color: var(--whiteColor);
                height: 45px;
                width: 45px;
                position: absolute;
                right: 5px;
                transition: var(--transition);
                border-radius: 5px;
                top: 5px;
                font-size: 20px;

                &:hover,
                &:focus {
                    background-color: var(--optionalColor);
                    color: var(--whiteColor);
                }
            }
        }
    }
    .widget_ednuv_posts_thumb {
        position: relative;
        overflow: hidden;

        .item {
            overflow: hidden;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                display: block;
                position: relative;
                width: 80px;
                margin-right: 15px;
                z-index: 1;

                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    border-radius: 5px;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    }
                    &.bg1 {
                        background-image: url(assets/img/68028308_s-800x587.jpeg);
                    }
                    &.bg2 {
                        background-image: url(assets/img/68028308_s-800x587.jpeg);
                    }
                    &.bg3 {
                        background-image: url(assets/img/68028308_s-800x587.jpeg);
                    }
                }
                &::before,
                &::after {
                    transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
                    content: "";
                    background-color: var(--whiteColor);
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    opacity: 0;
                    transform: translate(-50%, -50%);
                }
                &::before {
                    width: 40px;
                    height: 1px;
                    left: 100%;
                }
                &::after {
                    height: 40px;
                    width: 1px;
                    top: 0;
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;

                time {
                    display: block;
                    color: var(--paragraphColor);
                    text-transform: uppercase;
                    margin: {
                        top: 3px;
                        bottom: 5px;
                    }
                    font: {
                        size: 12px;
                    }
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.5;
                    font: {
                        size: 15px;
                        weight: 600;
                    }
                    a {
                        display: inline-block;
                    }
                }
            }
            &:hover {
                .thumb {
                    &::before,
                    &::after {
                        opacity: 1;
                        top: 50%;
                        left: 50%;
                    }
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                overflow: hidden;
                color: var(--blackColor);
                padding-left: 18px;
                font: {
                    weight: 600;
                    size: 14px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: var(--optionalColor);
                    height: 8px;
                    width: 8px;
                    content: "";
                    border-radius: 50%;
                    left: 0;
                    top: 7px;
                    position: absolute;
                }
                a {
                    color: var(--blackColor);
                    display: inline-block;

                    &:hover {
                        color: var(--mainColor);
                    }
                }
                .post-count {
                    color: var(--paragraphColor);
                    font-size: 14px;
                    display: inline-block;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
    .widget_tag_cloud {
        .widget-title {
            margin-bottom: 17px;
        }
    }
    .tagcloud {
        a {
            display: inline-block;
            background-color: #f6f6f6;
            color: var(--paragraphColor);
            padding: 7px 18px;
            border: 1px dashed #eeeeee;
            border-radius: 3px;
            font: {
                weight: 500;
                size: 13.5px !important;
            }
            margin: {
                top: 8px;
                right: 8px;
            }
            &:hover,
            &:focus {
                color: var(--whiteColor);
                background-color: var(--mainColor);
                border-color: var(--mainColor);
            }
        }
    }
    .widget_instagram {
        ul {
            padding-left: 0;
            list-style-type: none;
            flex-wrap: wrap;
            display: flex;
            margin: {
                bottom: -10px;
                left: -5px;
                right: -5px;
            }
            li {
                flex: 0 0 33.3333333333%;
                max-width: 33.3333333333%;
                padding: {
                    bottom: 10px;
                    right: 5px;
                    left: 5px;
                }
                a {
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: var(--mainColor);
                        transition: var(--transition);
                        opacity: 0;
                        visibility: hidden;
                    }
                    &:hover {
                        &::before {
                            opacity: 0.7;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    .widget_contact {
        position: relative;
        z-index: 1;
        border-radius: 5px;
        height: 400px;
        text-align: center;
        background: {
            image: url(assets/img/mission-bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }
        &::before {
            width: 100%;
            height: 100%;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 5px;
            background-color: var(--mainColor);
            opacity: 0.6;
            z-index: -1;
        }
        .text {
            position: absolute;
            left: 0;
            bottom: 25px;
            right: 0;

            .icon {
                width: 65px;
                height: 65px;
                border: 2px dashed var(--whiteColor);
                position: relative;
                color: var(--whiteColor);
                border-radius: 50%;
                font-size: 35px;
                transition: var(--transition);
                margin: {
                    left: auto;
                    right: auto;
                    bottom: 15px;
                }
                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            span {
                display: block;
                color: var(--whiteColor);
                margin-bottom: 7px;
                opacity: 0.9;
                font: {
                    size: 16px;
                    weight: 500;
                }
            }
            a {
                display: inline-block;
                color: var(--whiteColor);
                font: {
                    weight: 700;
                    size: 25px;
                }
            }
        }
        &:hover {
            .text {
                .icon {
                    background-color: var(--whiteColor);
                    color: var(--mainColor);
                    border-color: var(--whiteColor);
                }
            }
        }
    }
    .widget_events_details {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                border-bottom: 1px dashed #eeeeee;
                color: var(--paragraphColor);
                padding: {
                    bottom: 10px;
                    top: 10px;
                }
                &:first-child {
                    border-top: 1px dashed #eeeeee;
                }
                span {
                    display: inline-block;
                    color: var(--blackColor);
                    font: {
                        weight: 600;
                    }
                }
                a {
                    display: inline-block;
                    color: var(--paragraphColor);
                    margin-left: 3px;

                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }
    }
}

/*Pagination CSS*/
.pagination-area {
    position: relative;
    z-index: 2;
    margin: {
        top: 20px;
        bottom: -12px;
    }
    .page-numbers {
        color: var(--blackColor);
        display: inline-block;
        font: {
            weight: 500;
            size: 19px;
        }
        margin: {
            left: 6px;
            right: 6px;
        }
        &:hover,
        &:focus,
        &.current {
            color: var(--mainColor);
        }
        &.prev,
        &.next {
            font-size: 28px;
            position: relative;
            top: 5px;
            margin: {
                left: -3px;
                right: -3px;
            }
        }
    }
}

/*post-navigation*/
.ednuv-post-navigation {
    display: flex;
    flex-wrap: wrap;
    margin: {
        top: 30px;
    }
    padding: {
        top: 30px;
        bottom: 30px;
    }
    border: {
        top: 1px solid #eeeeee;
        bottom: 1px solid #eeeeee;
    }
}
.prev-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    padding: {
        right: 15px;
    }
    a {
        display: flex;
        align-items: center;

        &:hover {
            .image-prev {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
                .post-nav-title {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .prev-link-info-wrapper {
                color: var(--mainColor);
            }
        }
    }
    .image-prev {
        display: inline-block;
        min-width: 100px;
        width: 100px;
        border-radius: 5px;
        overflow: hidden;
        vertical-align: top;
        margin-right: 20px;
        position: relative;
        transition: var(--transition);

        img {
            border-radius: 5px;
        }
        &::after {
            display: block;
            content: "";
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
            opacity: 0;
            background-color: var(--mainColor);
            visibility: hidden;
            transition: var(--transition);
        }
        .post-nav-title {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin: 0 auto;
            text-align: center;
            text-transform: uppercase;
            z-index: 2;
            color: var(--whiteColor);
            opacity: 0;
            visibility: hidden;
            transition: var(--transition);
            font: {
                size: 16px;
                weight: 500;
            }
        }
    }
    .prev-link-info-wrapper {
        color: var(--blackColor);
        transition: var(--transition);
    }
    .prev-title {
        display: inline-block;
        font: {
            weight: 500;
            size: 17px;
        }
    }
    .meta-wrapper {
        display: block;
        color: var(--paragraphColor);
        text-transform: capitalize;
        margin-top: 5px;
        font: {
            weight: 400;
            size: 14px;
        }
    }
}
.next-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: end;
    padding: {
        left: 15px;
    }
    a {
        display: flex;
        align-items: center;

        &:hover {
            .image-next {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
                .post-nav-title {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .next-link-info-wrapper {
                color: var(--mainColor);
            }
        }
    }
    .image-next {
        display: inline-block;
        min-width: 100px;
        width: 100px;
        border-radius: 5px;
        overflow: hidden;
        vertical-align: top;
        margin-left: 20px;
        position: relative;
        transition: var(--transition);

        img {
            border-radius: 5px;
        }
        &::after {
            display: block;
            content: "";
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
            opacity: 0;
            background-color: var(--mainColor);
            visibility: hidden;
            transition: var(--transition);
        }
        .post-nav-title {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin: 0 auto;
            text-align: center;
            text-transform: uppercase;
            z-index: 2;
            color: var(--whiteColor);
            opacity: 0;
            visibility: hidden;
            transition: var(--transition);
            font: {
                size: 16px;
                weight: 500;
            }
        }
    }
    .next-link-info-wrapper {
        transition: var(--transition);
        color: var(--blackColor);
    }
    .next-title {
        display: inline-block;
        font: {
            weight: 500;
            size: 17px;
        }
    }
    .meta-wrapper {
        display: block;
        color: var(--paragraphColor);
        text-transform: capitalize;
        margin-top: 5px;
        font: {
            weight: 400;
            size: 14px;
        }
    }
}

/*quote*/
blockquote,
.blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 50px !important;
    position: relative;
    text-align: center;
    z-index: 1;
    margin: {
        bottom: 20px;
        top: 20px;
    }
    p {
        color: var(--blackColor);
        line-height: 1.6;
        margin-bottom: 0;
        font: {
            style: italic;
            weight: 500;
            size: 22px !important;
        }
    }
    cite {
        display: none;
    }
    &::before {
        color: #efefef;
        position: absolute;
        left: 50px;
        top: -50px;
        z-index: -1;
        content: "\ed67";
        font: {
            family: "boxicons" !important;
            weight: normal;
            style: normal;
            variant: normal;
            size: 135px;
        }
    }
    &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: var(--mainColor);
        margin: {
            top: 20px;
            bottom: 20px;
        }
    }
}

@media only screen and (max-width: 767px) {
    body {
        font-size: 14px;
    }
    .ptb-100 {
        padding: {
            top: 60px;
            bottom: 60px;
        }
    }
    .pt-100 {
        padding-top: 60px;
    }
    .pb-100 {
        padding-bottom: 60px;
    }
    .ptb-70 {
        padding: {
            top: 60px;
            bottom: 60px;
        }
    }
    .pt-70 {
        padding-top: 30px;
    }
    .pb-70 {
        padding-bottom: 30px;
    }
    p {
        font-size: 14px;
    }
    .section-title {
        margin-bottom: 35px;

        .sub-title {
            margin-bottom: 8px;
            font-size: 13.5px;
        }
        h2 {
            font-size: 27px;
        }
        p {
            max-width: 100%;
        }
        &.text-start {
            text-align: center !important;

            h2 {
                max-width: 100%;
            }
            .default-btn {
                display: none;
            }
        }
    }
    .default-btn {
        padding: 12px 20px 11px 50px;
        font-size: 13px;

        .icon-arrow {
            top: 10px;
            font-size: 22px;
        }
    }

    .mission-content {
        .owl-theme {
            .owl-nav {
                [class*="owl-"] {
                    font-size: 25px;
                    width: 35px;
                    height: 35px;
                    line-height: 41px;
                    left: -30px;

                    &.owl-next {
                        left: auto;
                        right: -30px;
                    }
                }
            }
        }
    }

    .funfacts-inner {
        border-radius: 10px;
        padding: 25px 20px 0;

        .col-lg-3 {
            border-right: none;
        }
    }
    .products-details-tab {
        .tabs-container {
            padding: 20px;

            .products-details-tab-content {
                .additional-information {
                    li {
                        span {
                            display: block;
                            width: 100%;
                            margin-bottom: 3px;
                        }
                    }
                }
                h3 {
                    font-size: 18px;
                }
                .product-review-form {
                    .review-title {
                        .default-btn {
                            position: relative;
                            margin-top: 15px;
                        }
                    }
                    .review-comments {
                        .review-item {
                            margin-top: 30px;
                            position: relative;
                            padding-right: 0;
                            border-top: 1px dashed #eeeeee;
                            padding-top: 30px;
                        }
                    }
                }
            }
        }
    }

    .instructor-area {
        overflow: hidden;
    }
    .faq-accordion-tab {
        .nav-tabset {
            margin-bottom: 40px;

            li {
                margin-bottom: 10px;

                span {
                    padding: 10px;
                    font-size: 14px;
                }
            }
        }
    }

    .feedback-slides {
        .owl-theme {
            margin-bottom: 0;

            .single-feedback-item {
                margin-bottom: 30px;
            }
            .owl-nav {
                [class*="owl-"] {
                    font-size: 22px;
                    width: 40px;
                    height: 40px;
                    line-height: 42px;
                    position: relative;
                    left: 0 !important;
                    top: 0;
                    transform: unset;
                    opacity: 1;
                    visibility: visible;
                    margin: {
                        left: 5px;
                        right: 5px;
                    }
                    &.owl-next {
                        left: auto !important;
                        right: 0 !important;
                    }
                }
            }
        }
    }

    .nav-tabset {
        text-align: center;
        margin: {
            top: 0;
            bottom: 22px;
        }
        .nav-tab {
            margin: {
                left: 8px;
                right: 8px;
                bottom: 8px;
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .courses-list-tab {
        .nav-tabset {
            margin-bottom: 35px;
            padding: {
                left: 0;
                right: 0;
            }
            .nav-tab {
                display: block;
                margin: {
                    left: 0;
                    right: 0;
                }
                span {
                    display: block;
                    font-size: 14px;
                    padding: {
                        top: 10px;
                        bottom: 10px;
                    }
                }
            }
        }
    }

    .blog-slides {
        .owl-theme {
            .owl-nav {
                margin-bottom: 30px;

                [class*="owl-"] {
                    font-size: 22px;
                    width: 40px;
                    height: 40px;
                    line-height: 42px;
                    position: relative;
                    left: 0 !important;
                    top: 0;
                    transform: unset;
                    opacity: 1;
                    visibility: visible;
                    margin: {
                        left: 5px;
                        right: 5px;
                    }
                    &.owl-next {
                        left: auto !important;
                        right: 0 !important;
                    }
                }
            }
        }
    }

    .courses-categories-slides {
        .owl-theme {
            .owl-nav {
                margin-bottom: 30px;

                [class*="owl-"] {
                    font-size: 22px;
                    width: 40px;
                    height: 40px;
                    line-height: 42px;
                    position: relative;
                    left: 0 !important;
                    top: 0;
                    transform: unset;
                    opacity: 1;
                    visibility: visible;
                    margin: {
                        left: 5px;
                        right: 5px;
                    }
                    &.owl-next {
                        left: auto !important;
                        right: 0 !important;
                    }
                }
            }
        }
    }

    .home-slides {
        .owl-theme {
            .owl-nav {
                [class*="owl-"] {
                    left: 10px;
                    font-size: 27px;
                    width: 35px;
                    height: 35px;

                    i {
                        top: 55%;
                        transform: translateY(-55%);
                    }
                    &.owl-next {
                        left: auto;
                        right: 10px;
                    }
                }
            }
        }
    }

    .instructor-slides {
        .owl-theme {
            .owl-nav {
                margin-bottom: 30px;

                [class*="owl-"] {
                    font-size: 22px;
                    width: 40px;
                    height: 40px;
                    line-height: 42px;
                    position: relative;
                    left: 0 !important;
                    top: 0;
                    transform: unset;
                    opacity: 1;
                    visibility: visible;
                    margin: {
                        left: 5px;
                        right: 5px;
                    }
                    &.owl-next {
                        left: auto !important;
                        right: 0 !important;
                    }
                }
            }
        }
    }

    .feedback-form-area {
        margin-top: -10px;
    }

    .divider {
        bottom: -1px;
    }

    .courses-slides {
        .owl-theme {
            .owl-nav {
                [class*="owl-"] {
                    font-size: 22px;
                    width: 40px;
                    height: 40px;
                    line-height: 42px;
                    position: relative;
                    left: 0 !important;
                    top: 0;
                    transform: unset;
                    opacity: 1;
                    visibility: visible;
                    margin: {
                        left: 5px;
                        right: 5px;
                    }
                    &.owl-next {
                        left: auto !important;
                        right: 0 !important;
                    }
                }
            }
            .owl-dots {
                display: none !important;
            }
        }
    }

    .testimonials-slides {
        .owl-theme {
            .owl-nav {
                &.disabled + .owl-dots {
                    margin-top: 0;
                }
            }
        }
    }

    .courses-topbar {
        text-align: center;

        .topbar-result-count {
            p {
                font-size: 15px;
            }
        }
        .topbar-ordering {
            margin: {
                top: 5px;
                bottom: 7px;
            }
        }
    }

    .page-title-area {
        padding: {
            top: 265px;
            bottom: 90px;
        }
        &.page-title-style-two {
            padding: {
                top: 270px;
                bottom: 100px;
            }
        }
        &.page-title-style-three {
            padding: {
                top: 100px;
                bottom: 100px;
            }
        }
    }
    .page-title-content {
        h2 {
            font-size: 30px;
        }
    }

    blockquote,
    .blockquote {
        padding: 20px !important;

        p {
            font-size: 17px !important;
        }
    }
    .prev-link-wrapper {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0;

        .prev-title {
            font-size: 15px;
        }
        .meta-wrapper {
            font-size: 13px;
        }
    }
    .next-link-wrapper {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
        margin-top: 30px;

        .next-title {
            font-size: 15px;
        }
        .meta-wrapper {
            font-size: 13px;
        }
    }

    .widget-area {
        margin-top: 40px;

        .widget {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
            .widget-title {
                font-size: 17px;
            }
        }
        .widget_ednuv_posts_thumb {
            .item {
                .info {
                    margin-top: 0;

                    time {
                        margin-top: 0;
                    }
                    .title {
                        font-size: 14px;
                    }
                }
            }
        }
        .widget_categories {
            ul {
                li {
                    font-size: 13px;

                    &::before {
                        top: 6px;
                    }
                }
            }
        }
        .tagcloud {
            a {
                padding: 6px 15px;
                font-size: 13px !important;
            }
        }
    }

    .pagination-area {
        margin-top: 0;
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .page-title-area {
        padding-top: 240px;
    }

    .courses-topbar {
        .topbar-result-count {
            margin-bottom: 10px;
        }
        .topbar-ordering {
            margin: {
                top: 0;
                bottom: 0;
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .ptb-100 {
        padding: {
            top: 80px;
            bottom: 80px;
        }
    }
    .pt-100 {
        padding-top: 80px;
    }
    .pb-100 {
        padding-bottom: 80px;
    }
    .ptb-70 {
        padding: {
            top: 50px;
            bottom: 50px;
        }
    }
    .pt-70 {
        padding-top: 50px;
    }
    .pb-70 {
        padding-bottom: 50px;
    }
    .section-title {
        margin-bottom: 50px;

        &.text-start {
            text-align: center !important;

            h2 {
                max-width: 100%;
            }
            .default-btn {
                display: none;
            }
        }
    }

    .divider {
        bottom: -1px;
    }

    .nav-tabset {
        text-align: center;
        margin: {
            top: -15px;
            bottom: 20px;
        }
        .nav-tab {
            margin: {
                left: 8px;
                right: 8px;
                bottom: 10px;
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .courses-list-tab {
        .nav-tabset {
            .nav-tab {
                margin-bottom: 0;
            }
        }
    }

    .courses-categories-slides {
        .owl-theme {
            .owl-nav {
                margin-bottom: 30px;

                [class*="owl-"] {
                    font-size: 22px;
                    width: 40px;
                    height: 40px;
                    line-height: 42px;
                    position: relative;
                    left: 0 !important;
                    top: 0;
                    transform: unset;
                    opacity: 1;
                    visibility: visible;
                    margin: {
                        left: 5px;
                        right: 5px;
                    }
                    &.owl-next {
                        left: auto !important;
                        right: 0 !important;
                    }
                }
            }
        }
    }

    .funfacts-inner {
        padding: 30px;
    }

    .courses-slides {
        .owl-theme {
            .owl-nav {
                [class*="owl-"] {
                    font-size: 22px;
                    width: 40px;
                    height: 40px;
                    line-height: 42px;
                    position: relative;
                    left: 0 !important;
                    top: 0;
                    transform: unset;
                    opacity: 1;
                    visibility: visible;
                    margin: {
                        left: 5px;
                        right: 5px;
                    }
                    &.owl-next {
                        left: auto !important;
                        right: 0 !important;
                    }
                }
            }
            .owl-dots {
                display: none !important;
            }
        }
    }

    .instructor-slides {
        .owl-theme {
            .owl-nav {
                margin-bottom: 30px;

                [class*="owl-"] {
                    font-size: 22px;
                    width: 40px;
                    height: 40px;
                    line-height: 42px;
                    position: relative;
                    left: 0 !important;
                    top: 0;
                    transform: unset;
                    opacity: 1;
                    visibility: visible;
                    margin: {
                        left: 5px;
                        right: 5px;
                    }
                    &.owl-next {
                        left: auto !important;
                        right: 0 !important;
                    }
                }
            }
        }
    }

    .feedback-form-area {
        margin-top: 0;
    }

    .feedback-slides {
        .owl-theme {
            .owl-nav {
                [class*="owl-"] {
                    left: 0 !important;
                    top: auto;
                    transform: unset;
                    bottom: 40px;
                    opacity: 1;
                    visibility: visible;

                    &.owl-next {
                        left: 75px !important;
                        right: auto;
                    }
                }
            }
        }
    }

    .blog-slides {
        .owl-theme {
            .owl-nav {
                margin-bottom: 30px;

                [class*="owl-"] {
                    font-size: 22px;
                    width: 40px;
                    height: 40px;
                    line-height: 42px;
                    position: relative;
                    left: 0 !important;
                    top: 0;
                    transform: unset;
                    opacity: 1;
                    visibility: visible;
                    margin: {
                        left: 5px;
                        right: 5px;
                    }
                    &.owl-next {
                        left: auto !important;
                        right: 0 !important;
                    }
                }
            }
        }
    }

    .home-slides {
        .owl-theme {
            .owl-nav {
                [class*="owl-"] {
                    top: auto;
                    transform: unset;
                    bottom: 20px;
                    z-index: 2;
                }
            }
        }
    }

    .widget-area {
        margin-top: 40px;
    }

    .page-title-area {
        padding: {
            top: 300px;
            bottom: 110px;
        }
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .courses-categories-slides {
        .owl-theme {
            .owl-nav {
                margin-bottom: 30px;

                [class*="owl-"] {
                    position: relative;
                    left: 0 !important;
                    top: 0;
                    transform: unset;
                    opacity: 1;
                    visibility: visible;
                    margin: {
                        left: 5px;
                        right: 5px;
                    }
                    &.owl-next {
                        left: 0 !important;
                        right: 0 !important;
                    }
                }
            }
        }
    }

    .blog-slides {
        .owl-theme {
            .owl-nav {
                margin: {
                    top: 10px;
                    bottom: 30px;
                }
                [class*="owl-"] {
                    position: relative;
                    left: 0 !important;
                    top: 0;
                    transform: unset;
                    opacity: 1;
                    visibility: visible;
                    margin: {
                        left: 5px;
                        right: 5px;
                    }
                    &.owl-next {
                        left: 0 !important;
                        right: 0 !important;
                    }
                }
            }
        }
    }

    .instructor-slides {
        .owl-theme {
            .owl-nav {
                margin: {
                    top: 10px;
                    bottom: 30px;
                }
                [class*="owl-"] {
                    position: relative;
                    left: 0 !important;
                    top: 0;
                    transform: unset;
                    opacity: 1;
                    visibility: visible;
                    margin: {
                        left: 5px;
                        right: 5px;
                    }
                    &.owl-next {
                        left: 0 !important;
                        right: 0 !important;
                    }
                }
            }
        }
    }

    .courses-slides {
        .owl-theme {
            .owl-nav {
                margin: {
                    top: 10px;
                    bottom: 30px;
                }
                [class*="owl-"] {
                    position: relative;
                    left: 0 !important;
                    top: 0;
                    transform: unset;
                    opacity: 1;
                    visibility: visible;
                    margin: {
                        left: 5px;
                        right: 5px;
                    }
                    &.owl-next {
                        left: 0 !important;
                        right: 0 !important;
                    }
                }
            }
            .owl-dots {
                display: none !important;
            }
        }
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1275px) {
    .courses-categories-slides {
        .owl-theme {
            .owl-nav {
                [class*="owl-"] {
                    left: -30px;

                    &.owl-next {
                        left: auto;
                        right: -30px;
                    }
                }
            }
            &:hover,
            &:focus {
                .owl-nav {
                    [class*="owl-"] {
                        left: -40px;

                        &.owl-next {
                            left: auto;
                            right: -40px;
                        }
                    }
                }
            }
        }
    }

    .courses-slides {
        .owl-theme {
            .owl-nav {
                [class*="owl-"] {
                    left: -30px;

                    &.owl-next {
                        left: auto;
                        right: -30px;
                    }
                }
            }
            &:hover,
            &:focus {
                .owl-nav {
                    [class*="owl-"] {
                        left: -40px;

                        &.owl-next {
                            left: auto;
                            right: -40px;
                        }
                    }
                }
            }
        }
    }

    .blog-slides {
        .owl-theme {
            .owl-nav {
                [class*="owl-"] {
                    left: -30px;

                    &.owl-next {
                        left: auto;
                        right: -30px;
                    }
                }
            }
            &:hover,
            &:focus {
                .owl-nav {
                    [class*="owl-"] {
                        left: -40px;

                        &.owl-next {
                            left: auto;
                            right: -40px;
                        }
                    }
                }
            }
        }
    }

    .instructor-slides {
        .owl-theme {
            .owl-nav {
                [class*="owl-"] {
                    left: -30px;

                    &.owl-next {
                        left: auto;
                        right: -30px;
                    }
                }
            }
            &:hover,
            &:focus {
                .owl-nav {
                    [class*="owl-"] {
                        left: -40px;

                        &.owl-next {
                            left: auto;
                            right: -40px;
                        }
                    }
                }
            }
        }
    }
}
html,
body {
    height: 100%;
}
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
body {
    margin: 0;
    // font-family: "sarabunlight";
    font-family: var(--fontFamilySarabun);
    // font-family: Verdana;
}
